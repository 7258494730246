(function () {
  "use strict";
  document.addEventListener('DOMContentLoaded', function () {

    /*Navigation Menu variables*/
    const navigator = document.querySelector('#navigator');
    const solutions = document.querySelector('#solutions');
    const company = document.querySelector('#company');
    const solutionsCard = document.querySelector('#card-solutions');
    const companyCard = document.querySelector('#card-company');
    const pmSection = document.querySelector('#pmSection');
    const imSection = document.querySelector('#imSection');
    const pcSection = document.querySelector('#pcSection');
    const dbrSection = document.querySelector('#dbrSection');
    const auSection = document.querySelector('#auSection');
    const diffSection = document.querySelector('#diffSection');
    const pmBanner = document.querySelector('#pm-banner');
    const imBanner = document.querySelector('#im-banner');
    const pcBanner = document.querySelector('#pc-banner');
    const dbrBanner = document.querySelector('#dbr-banner');
    const auBanner = document.querySelector('#au-banner');
    const diffBanner = document.querySelector('#diff-banner');

    /*Hamburger nav bar variables*/
    const hamMenu = document.querySelector('#ham-menu');
    const close = document.querySelector('#close');
    const hamList = document.querySelector('#ham-list');
    const arrowSolutions = document.querySelector('#arrow-solutions');
    const arrowCompany = document.querySelector('#arrow-company');
    const arrowLanguage = document.querySelector('#arrow-language');
    const solutionsTitle = document.querySelector('#solutions-title');
    const companyTitle = document.querySelector('#company-title');
    const solutionsList = document.querySelector('#solutions-list');
    const companyList = document.querySelector('#company-list');
    const languageTitle = document.querySelector('#language-title');
    const languageList = document.querySelector('#language-list');

    /*Functionality in hamburger nav bar*/
    hamMenu.addEventListener('click', function () {
      hamList.classList.add('display');
      hamMenu.style.display = 'none';
      close.classList.add('display');
    });

    close.addEventListener('click', function () {
      hamList.classList.remove('display');
      solutionsList.classList.remove('display');
      arrowSolutions.classList.remove('rotate');
      companyList.classList.remove('display');
      arrowCompany.classList.remove('rotate');
      languageList.classList.remove('display');
      arrowLanguage.classList.remove('rotate');
      close.classList.remove('display');
      hamMenu.style.display = 'block';
    });

    solutionsTitle.addEventListener('click', function () {
      hamList.classList.add('display');
      arrowSolutions.classList.toggle('rotate');
      solutionsList.classList.toggle('display');
    });

    companyTitle.addEventListener('click', function () {
      hamList.classList.add('display');
      arrowCompany.classList.toggle('rotate');
      companyList.classList.toggle('display');
    });

    languageTitle.addEventListener('click', function () {
      hamList.classList.add('display');
      arrowLanguage.classList.toggle('rotate');
      languageList.classList.toggle('display');
    });

    /*Additional effect in nav bar menu*/
    solutions.addEventListener('mouseover', function () {
      solutionsCard.classList.add('display');
      companyCard.classList.remove('display');
    });

    navigator.addEventListener('mouseleave', function () {
      solutionsCard.classList.remove('display');
      companyCard.classList.remove('display');
    });

    company.addEventListener('mouseover', function () {
      companyCard.classList.add('display');
      solutionsCard.classList.remove('display');
    });

    /*Display or not a summary of page in menu*/
    function showMenuCard() {

      pmSection.addEventListener('mouseover', function () {
        this.classList.add('active');
        imSection.classList.remove('active');
        pcSection.classList.remove('active');
        dbrSection.classList.remove('active');

        pmBanner.classList.add('display');
        imBanner.classList.remove('display');
        pcBanner.classList.remove('display');
        dbrBanner.classList.remove('display');
      });

      imSection.addEventListener('mouseover', function () {
        this.classList.add('active');
        pmSection.classList.remove('active');
        pcSection.classList.remove('active');
        dbrSection.classList.remove('active');

        imBanner.classList.add('display');
        pmBanner.classList.remove('display');
        pcBanner.classList.remove('display');
        dbrBanner.classList.remove('display');
      });

      pcSection.addEventListener('mouseover', function () {
        this.classList.add('active');
        pmSection.classList.remove('active');
        imSection.classList.remove('active');
        dbrSection.classList.remove('active');

        pcBanner.classList.add('display');
        pmBanner.classList.remove('display');
        imBanner.classList.remove('display');
        dbrBanner.classList.remove('display');
      });

      dbrSection.addEventListener('mouseover', function () {
        this.classList.add('active');
        pmSection.classList.remove('active');
        imSection.classList.remove('active');
        pcSection.classList.remove('active');

        dbrBanner.classList.add('display');
        pmBanner.classList.remove('display');
        imBanner.classList.remove('display');
        pcBanner.classList.remove('display');
      });

      auSection.addEventListener('mouseover', function () {
        this.classList.add('active');
        diffSection.classList.remove('active');

        auBanner.classList.add('display');
        diffBanner.classList.remove('display');
      });

      diffSection.addEventListener('mouseover', function () {
        this.classList.add('active');
        auSection.classList.remove('active');

        diffBanner.classList.add('display');
        auBanner.classList.remove('display');
      });
    }

    showMenuCard();

    /* Scroll nav-bar function */
    function scrollMenu() {
      let prevScroll = window.pageYOffset;
      window.onscroll = function () {
        let currentScroll = window.pageYOffset;
        if (prevScroll > currentScroll) {
          solutionsCard.classList.remove('display');
          companyCard.classList.remove('display');
        } else {
          solutionsCard.classList.remove('display');
          companyCard.classList.remove('display');
        }
        prevScroll = currentScroll;
      }
    }

    scrollMenu();

    /**Functionality of 404 Go Back button**/
    const goBack = document.querySelector('#goBack');
    if (typeof (goBack) != 'undefined' && goBack != null) {
      goBack.addEventListener('click', function () {
        goBack.style.pointerEvents = 'none';
        window.history.back();
      });
    }

    /**Scroll Magic Effects**/
    const controller = new ScrollMagic.Controller();

    if (screen.width >= 1024) {
      scrollFading();
      delayFading();
      scrollDetect();
      slideTop();
      detectReverse();
      reverseCarousel();
      invoiceCarousel();
    }

    function scrollFading() {
      new ScrollMagic.Scene({
        triggerElement: ".scroll-fading",
        triggerHook: 0.8,
        offset: 50,
        reverse: false
      })
          .setClassToggle(".scroll-fading", "visible")
          .addTo(controller);
    }

    function delayFading() {
      new ScrollMagic.Scene({
        triggerElement: ".delay-fading",
        triggerHook: 0.6,
        offset: 50,
        reverse: false
      })
          .setClassToggle(".delay-fading", "visible")
          .on("enter", clockWiseFade)
          .addTo(controller);
    }
    
    function scrollDetect() {
      new ScrollMagic.Scene({
        triggerElement: ".scroll-detect",
        triggerHook: 0.87,
        offset: 20,
        reverse: false
      })
          .setClassToggle(".scroll-detect", "visible")
          .on("enter", slideRight)
          .addTo(controller);
    }

    function clockWiseFade() {
      let subSection = document.querySelectorAll('.fade-element');
      for (let i = 0; i < subSection.length; i++) {
        subSection[i].classList.add('clock-wise-fade');
        subSection[i].style.animationDelay = `${(i + 1) * 200}ms`;
      }
    }

    function slideRight() {
      let subCards = document.querySelectorAll('.slide-element');
      for (let i = 0; i < subCards.length; i++) {
        subCards[i].classList.add('slide-right');
        subCards[i].style.animationDelay = `${(i) / 2}s`;
      }
    }

    function slideTop() {
      let subCards = document.querySelectorAll('.slide-top');
      for (let i = 0; i < subCards.length; i++) {
        subCards[i].style.animationDelay = `${i / 2}s`;
      }
    }

    function detectReverse(){
      let aboutUs = window.location.pathname;
      let reverse = true;
      if(aboutUs === "/about_us.html"){
        reverse = false;
        return reverse
      } else {
        reverse = true;
        return reverse
      }
    }

    function reverseCarousel() {
      let revealElements = document.getElementsByClassName("reverse-carousel");
      for (let i = 0; i < revealElements.length; i++) {
        new ScrollMagic.Scene({
          triggerElement: revealElements[i],
          offset: 50,
          triggerHook: 0.8,
          reverse: detectReverse()
        })
            .setClassToggle(revealElements[i], "visible")
            .addTo(controller);
      }
    }

    function invoiceCarousel() {
      let revealElements = document.getElementsByClassName("invoice-carousel");
      for (let i = 0; i < revealElements.length; i++) {
        new ScrollMagic.Scene({
          triggerElement: revealElements[i],
          offset: 50,
          triggerHook: 0.8,
          reverse: detectReverse()
        })
            .setClassToggle(revealElements[i], "visible")
            .addTo(controller);
      }
    }


  });
})();
